import React, { useContext, useEffect, useState } from "react";
import { ExchangeAppContext } from "../context/ExchangeAppContext";
import { useTranslation } from "../hooks/useTranslation";

const OfferList = () => {
  const { t } = useTranslation();
  const {
    giveCurrency,
    getCurrency,
    city,
    findExchangeRate,
    setGiveCurrency,
    setGetCurrency,
    setCity,
  } = useContext(ExchangeAppContext);
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isMenuCompressed, setIsMenuCompressed] = useState(false);

  useEffect(() => {
    if (giveCurrency && getCurrency && city) {
      setLoading(true);

      findExchangeRate(giveCurrency, getCurrency, city)
        .then((results) => {
          const ascendingPairs = new Set([
            "RUB_USDT",
            "RUB_USD",
            "ARS_USDT",
            "ARS_USD",
            "ARS_RUB",
          ]);

          const currencyPair = `${giveCurrency}_${getCurrency}`;

          if (ascendingPairs.has(currencyPair)) {
            results.sort((a, b) => parseFloat(a.Price) - parseFloat(b.Price));
          } else {
            results.sort((a, b) => parseFloat(b.Price) - parseFloat(a.Price));
          }

          setOffers(results);
          const choice = document.querySelector("#choice");
          choice.classList.add("visible");

          setIsMenuCompressed(results.length === 0 || results.length > 0);
        })
        .catch((err) => console.error("Ошибка поиска:", err))
        .finally(() => setLoading(false));
    }
  }, [giveCurrency, getCurrency, city, findExchangeRate]);

  useEffect(() => {
    const exchangeBox = document.querySelector(".exchange-box");
    const give = document.querySelector(".give");
    const get = document.querySelector(".get");
    const cityElement = document.querySelector(".city");
    const headers = document.querySelectorAll(".give h2, .get h2, .city h2");
    const buttons = document.querySelectorAll(
      ".give button, .get button, .city button"
    );
    var partners_container = document.querySelector(".partners-container");

    if (isMenuCompressed) {
      exchangeBox.style.transition = "all 0.5s linear";
      exchangeBox.style.padding = "10px";
      exchangeBox.style.flexDirection = "row";
      exchangeBox.style.gap = "10px";
      give.style.width = "50%";
      get.style.width = "50%";
      give.style.margin = "0";
      get.style.margin = "0";
      cityElement.classList.add("display_none");
      partners_container.classList.add("display_none");

      headers.forEach((header) => {
        header.style.display = "none";
      });

      buttons.forEach((button) => {
        button.style.pointerEvents = "none";
      });

      const downArrowButton = document.createElement("button");
      downArrowButton.innerHTML = `
        <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 11L12 15M12 15L16 11M12 15V3M7 4.51555C4.58803 6.13007 3 8.87958 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 8.87958 19.412 6.13007 17 4.51555" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      `;
      downArrowButton.classList.add("downArrowButton");

      downArrowButton.style.position = "absolute";
      downArrowButton.style.bottom = "-15px";
      downArrowButton.style.left = "50%";
      downArrowButton.style.transform = "translateX(-50%)";
      downArrowButton.style.width = "75px";
      downArrowButton.style.height = "33px";
      downArrowButton.style.borderRadius = "35%";
      downArrowButton.style.border = `2px solid var(--tg-theme-secondary-bg-color, #333)`;
      downArrowButton.style.backgroundColor = " rgb(246, 255, 95";
      downArrowButton.style.color = `#333`;
      downArrowButton.style.cursor = "pointer";
      downArrowButton.style.zIndex = "10";

      exchangeBox.style.position = "relative";
      exchangeBox.appendChild(downArrowButton);

      downArrowButton.addEventListener("click", () => {
        setIsMenuCompressed(false);
        setGiveCurrency(null);
        setGetCurrency(null);
        setCity(null);
        var choice = document.querySelector("#choice");
        choice.classList.remove("visible");
        var partners_container = document.querySelector(".partners-container");
        partners_container.classList.remove("display_none");
      });
    } else {
      exchangeBox.style.transition = "all 0.5s linear";
      exchangeBox.style.flexDirection = "column";
      give.style.width = "100%";
      get.style.width = "100%";
      cityElement.classList.remove("display_none");

      headers.forEach((header) => {
        header.style.display = "block";
      });

      buttons.forEach((button) => {
        button.style.pointerEvents = "auto";
      });

      const downArrowButton = document.querySelector(".downArrowButton");
      if (downArrowButton) {
        downArrowButton.remove();
      }
    }
  }, [isMenuCompressed]);

  if (loading) {
    return <p>Загрузка...</p>;
  }

  return (
    <div className="offers-container">
      {offers.length < 1 ? (
        <div className="no-offers">
          <h3>{t("noOffers")}</h3>
          <div className="margin_top_25">
            <img src="./img/capybara.png" alt="Capybara" />
            <p>{t("advertise")}</p>

            <a href="https://t.me/A43721">
              <button className="btn_ad">
                <div className="card">
                  <span className="text_centr">{t("writeMe")}</span>
                </div>
              </button>
            </a>
          </div>
        </div>
      ) : (
        <>
          <div className="best-rate">
            <p>
              {t("exchange_info", {
                giveCurrency: giveCurrency,
                getCurrency: getCurrency,
                city: city,
              })}
            </p>
            <p>{t("exchange")}</p>

            <div className="wr_text">
              <div className="wr_text_rate">
                <img
                  src="./img/verified.png"
                  alt="Verified"
                  style={{ width: "35px", height: "35px" }}
                />
                <p>{t("verifiedExchanger")}</p>
              </div>
              <div className="wr_text_rate">
                <img
                  src="./img/delivery.png"
                  alt="Delivery"
                  style={{ width: "35px", height: "35px" }}
                />
                <p>{t("homeDelivery")}</p>
              </div>
            </div>
          </div>

          {offers.map((offer, index) => {
            let rateText;

            if (offer.Price < 0) {
              rateText = `100 ${giveCurrency} = ${
                offer.Price * 100
              } ${getCurrency}`;
            } else if (giveCurrency === "ARS") {
              rateText = `${offer.Price} ${giveCurrency} = 1 ${getCurrency}`;
            } else if ((giveCurrency === "RUB") & (getCurrency !== "ARS")) {
              rateText = `${offer.Price} ${giveCurrency} = 1 ${getCurrency}`;
            } else {
              rateText = `1 ${giveCurrency} = ${offer.Price} ${getCurrency}`;
            }

            return (
              <a
                key={index}
                href={`${offer.TG_url}`}
                target="_blank"
                rel="noopener noreferrer"
                className="exchange-box"
              >
                <div className="wrap_rate">
                  <div className="wrap_rate_top">
                    {offer.Logo_url && (
                      <img
                        src={`${offer.Logo_url}`}
                        alt="logo"
                        style={{
                          width: "35px",
                          height: "35px",

                          borderRadius: "50%",
                        }}
                      />
                    )}
                    <h3>{offer.Name}</h3>
                  </div>
                  {offer.Verification === "on" && (
                    <img
                      src="./img/verified.png"
                      alt="Verified"
                      style={{ width: "35px", height: "35px" }}
                    />
                  )}
                </div>

                <div className="line"></div>

                <div className="wrap_rate">
                  <h2>{rateText}</h2>
                  {offer.Delivery === "on" && (
                    <img
                      src="./img/delivery.png"
                      alt="Delivery"
                      style={{ width: "35px", height: "35px" }}
                    />
                  )}
                </div>
              </a>
            );
          })}
        </>
      )}
    </div>
  );
};

export default OfferList;
