import React, { useEffect, useState } from "react";
import ExchangeBox from "./components/ExchangeBox";
import OfferList from "./components/OfferList";
import PartnersList from "./components/PartnersList";
import { ExchangeAppProvider } from "./context/ExchangeAppContext";

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [isTelegram, setIsTelegram] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  useEffect(() => {
    try {
      const tg = window.Telegram.WebApp;
      if (tg?.initDataUnsafe?.user?.id) {
        setIsTelegram(true);
      }
    } catch (error) {
      console.error("Ошибка проверки Telegram:", error);
    }
  }, []);

  return (
    <ExchangeAppProvider>
      <div className="App">
        {isTelegram ? (
          <ExchangeAppProvider>
            <div className="App">
              <header onClick={toggleMenu}> </header>
              <section>
                <div className="container">
                  <ExchangeBox />
                </div>
              </section>
              <section id="choice">
                <div className="container">
                  <OfferList />
                </div>
              </section>
              <PartnersList isMenuOpen={isMenuOpen} />
            </div>
          </ExchangeAppProvider>
        ) : (
          <div className="container">
            <div className="no-offers">
              <h3>
                Это приложение доступно только через Telegram
                @CapybaraCambioRobot
              </h3>
              <div className="margin_top_25">
                <img src="./img/capybara.png" alt="Capybara" />
                <p>
                  Для корректной работы агрегатора, откройте наш бот в Telegram
                  и запустите приложение оттуда.{" "}
                </p>

                <a href="https://t.me/CapybaraCambioRobot">
                  <button className="btn_ad">
                    <div className="card">
                      <span className="text_centr">Перейти в Телеграм</span>
                    </div>
                  </button>
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </ExchangeAppProvider>
  );
}

export default App;
