import React, { useState, useContext } from "react";
import { ExchangeAppContext } from "../context/ExchangeAppContext";
import ChoiceModal from "./ChoiceModal";
import { useTranslation } from "../hooks/useTranslation";

const ExchangeBox = () => {
  const { t } = useTranslation();
  const {
    giveCurrency,
    getCurrency,
    city,
    setGiveCurrency,
    setGetCurrency,
    setCity,
  } = useContext(ExchangeAppContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");

  const handleCurrencyClick = (type) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  return (
    <div className="exchange-box">
      <div className="give">
        <h2>{t("give")}</h2>
        <button
          id="btn_give"
          className="btn"
          onClick={() => handleCurrencyClick("give")}
        >
          <div className="card">
            <div id="img_wr_give" className="img_wr">
              {giveCurrency && (
                <img
                  src={`./img/${giveCurrency}.png`}
                  alt={giveCurrency}
                  style={{ width: "50px", height: "50px" }}
                  onError={(e) => {
                    e.target.src = "./img/argentina.png";
                  }}
                />
              )}
            </div>
            <span className="span_text_give">
              {giveCurrency || t("select_currency")}
            </span>
          </div>
        </button>
      </div>

      <div className="get">
        <h2>{t("get")}</h2>
        <button
          id="btn_get"
          className="btn"
          onClick={() => handleCurrencyClick("get")}
          disabled={!giveCurrency}
        >
          <div className="card">
            <div id="img_wr_get" className="img_wr">
              {getCurrency && (
                <img
                  src={`./img/${getCurrency}.png`}
                  alt={getCurrency}
                  style={{ width: "50px", height: "50px" }}
                  onError={(e) => {
                    e.target.src = "./img/argentina.png";
                  }}
                />
              )}
            </div>
            <span className="span_text_get">
              {getCurrency || t("select_currency")}
            </span>
          </div>
        </button>
      </div>

      <div className="city">
        <h2>{t("city")}</h2>
        <button
          id="btn_city"
          className="btn"
          onClick={() => handleCurrencyClick("city")}
          disabled={!getCurrency}
        >
          <div className="card">
            <div id="img_wr_city" className="img_wr">
              {city && (
                <img
                  src={`./img/${city}.png`}
                  alt={city}
                  style={{ width: "50px", height: "50px" }}
                  onError={(e) => {
                    e.target.src = "./img/argentina.png";
                  }}
                />
              )}
            </div>
            <span className="span_city">{city || t("select_city")}</span>
          </div>
        </button>
      </div>

      {isModalOpen && (
        <div className={`modal-overlay ${isModalOpen ? "show" : ""}`}>
          <div className={`modal-content ${isModalOpen ? "show" : ""}`}>
            <ChoiceModal
              type={modalType}
              onClose={() => setIsModalOpen(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ExchangeBox;
