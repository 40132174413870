import React, { useContext, useEffect, useState } from "react";
import { ExchangeAppContext } from "../context/ExchangeAppContext";
import { useTranslation } from "../hooks/useTranslation";

const ChoiceModal = ({ type, onClose }) => {
  const { t } = useTranslation();
  const {
    setGiveCurrency,
    setGetCurrency,
    setCity,
    arrCurrency,
    arrCity,
    giveCurrency,
  } = useContext(ExchangeAppContext);

  const [list, setList] = useState([]);

  useEffect(() => {
    if (type === "city") {
      setList(arrCity);
    } else {
      setList(
        arrCurrency.filter(
          (currency) => type !== "get" || currency !== giveCurrency
        )
      );
    }
  }, [type, arrCurrency, arrCity, giveCurrency]);

  const handleChoiceClick = (item) => {
    if (type === "give") setGiveCurrency(item);
    if (type === "get") setGetCurrency(item);
    if (type === "city") setCity(item);
    onClose();
  };

  return (
    <div className="modal visible">
      <div className="wrap">
        <h2 className="text">
          {t(type === "city" ? "select_city" : "select_currency")}
        </h2>
        <button id="close-choice" onClick={onClose}>
          X
        </button>
      </div>
      <div className="list">
        {list.map((elem) => (
          <button
            key={elem}
            className="btn"
            onClick={() => handleChoiceClick(elem)}
          >
            <div className="card">
              <div className="img_wr">
                <img
                  src={`./img/${elem}.png`}
                  alt={elem}
                  style={{ width: "50px", height: "50px" }}
                  onError={(e) => {
                    e.target.src = "./img/argentina.png";
                  }}
                />
              </div>
              <span className="span_text_give">{elem}</span>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default ChoiceModal;
