import React, { createContext, useState, useEffect } from "react";
import fetchUniqueCities from "../services/fetchUniqueCities";

export const ExchangeAppContext = createContext();

export const ExchangeAppProvider = ({ children }) => {
  const [giveCurrency, setGiveCurrency] = useState(null);
  const [getCurrency, setGetCurrency] = useState(null);
  const [city, setCity] = useState(null);
  const [arrCurrency, setArrCurrency] = useState([]);
  const [arrCity, setArrCity] = useState([]);

  const fetchExchangeData = async () => {
    const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID;
    const API_KEY = process.env.REACT_APP_API_KEY;
    const RANGE = "Main";

    const response = await fetch(
      `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${RANGE}?key=${API_KEY}`
    );
    const data = await response.json();
    return data.values;
  };

  const fetchUniqueCurrencies = async () => {
    try {
      const data = await fetchExchangeData();
      const headers = data[0];
      const currencies = new Set();

      headers.forEach((header) => {
        if (header.includes("_")) {
          const [from, to] = header.split("_");
          currencies.add(from);
          currencies.add(to);
        }
      });

      setArrCurrency(Array.from(currencies));
    } catch (error) {
      console.error("Ошибка при получении уникальных валют:", error);
    }
  };

  useEffect(() => {
    fetchUniqueCities(fetchExchangeData).then((cities) => setArrCity(cities));
    fetchUniqueCurrencies();
  }, []);

  const findExchangeRate = async (currencyFrom, currencyTo, city) => {
    const data = await fetchExchangeData();
    const headers = data[0];
    const results = [];
    const res_currency = `${currencyFrom}_${currencyTo}`;
    const currencyIndex = headers.indexOf(res_currency);

    if (currencyIndex === -1) {
      console.error("Валюта не найдена в заголовках");
      return [];
    }

    for (let i = 1; i < data.length; i++) {
      const row = data[i];
      const rowData = headers.reduce((acc, header, index) => {
        acc[header] = row[index];
        return acc;
      }, {});

      if (rowData.Status === "on" && rowData.City === city) {
        let exchangeRate = rowData[res_currency];
        if (exchangeRate) {
          exchangeRate = parseFloat(exchangeRate.replace(",", "."));
          results.push({
            Name: rowData.Name,
            Price: exchangeRate,
            Delivery: rowData.Delivery,
            TG_url: rowData.TGurl,
            Logo_url: rowData.Logourl,
            Premium: rowData.Premium,
            Verification: rowData.Verification,
          });
        }
      }
    }

    return results;
  };

  return (
    <ExchangeAppContext.Provider
      value={{
        giveCurrency,
        getCurrency,
        city,
        setGiveCurrency,
        setGetCurrency,
        setCity,
        arrCurrency,
        arrCity,
        findExchangeRate,
      }}
    >
      {children}
    </ExchangeAppContext.Provider>
  );
};
