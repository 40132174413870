export const translations = {
  en: {
    noOffers: "No suitable exchange options available at the moment!",
    advertise: "If you want to list your exchange or report an error 👇",
    writeMe: "Write to me",
    exchange_info: "Exchange {giveCurrency} to {getCurrency} in {city}:",
    verifiedExchanger: "- Verified exchanger",
    homeDelivery: "- Home delivery",
    give: "GIVE:",
    get: "GET:",
    city: "CITY:",
    select_city: "Select city",
    select_currency: "Select currency",
    info_partners: "INFO PARTNERS:",
    info_tg: "OUR TELEGRAM BOT:",
  },

  es: {
    noOffers: "¡No hay opciones de cambio disponibles en este momento!",
    advertise: "Si desea publicar su exchange o informar un error 👇",
    writeMe: "Escríbeme",
    exchange_info: "Cambio de {giveCurrency} a {getCurrency} en {city}:",
    verifiedExchanger: "- Exchange verificado",
    homeDelivery: "- Entrega a domicilio",
    give: "DOY:",
    get: "CONSEGIR:",
    city: "CIUDAD:",
    select_city: "Seleccione ciudad",
    select_currency: "Seleccione moneda",
    info_partners: "SOCIOS DE INFORMACIÓN:",
    info_tg: "OUR TELEGRAM BOT:",
  },

  ru: {
    noOffers: "В данный момент нет подходящих вариантов для обмена!",
    advertise:
      "Если вы хотите разместить свой обменник у нас или сообщить об ошибке 👇",
    writeMe: "Напиши мне",
    exchange_info: "Обмен {giveCurrency} на {getCurrency} в {city}:",
    verifiedExchanger: "- Проверенный обменник",
    homeDelivery: "- Доставка домой",
    give: "ОТДАЮ:",
    get: "ПОЛУЧАЮ:",
    city: "ГОРОД:",
    select_city: "Выберите город",
    select_currency: "Выберите валюту",
    info_partners: "ИНФОПАРТНЕРЫ:",
    info_tg: "Мы в Telegram",
  },
};
