import { useState, useEffect } from "react";
import { translations } from "../translations";

export const useTranslation = () => {
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    const userLanguage =
      window.Telegram?.WebApp?.initDataUnsafe?.user?.language_code || "en";

    const supportedLanguage = ["ru", "en", "es"].includes(userLanguage)
      ? userLanguage
      : "en";
    setLanguage(supportedLanguage);
  }, []);

  const t = (key, params = {}) => {
    let text = translations[language][key] || translations["en"][key];

    Object.entries(params).forEach(([key, value]) => {
      text = text.replace(`{${key}}`, value);
    });

    return text;
  };

  return { t, language };
};
