import React, { useEffect, useState } from "react";
import { useTranslation } from "../hooks/useTranslation";

const PartnersList = ({ isMenuOpen }) => {
  const [partners, setPartners] = useState([]);
  const [isTelegramApp, setIsTelegramApp] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    // Проверка на запуск внутри Telegram Mini App
    const isTelegram =
      typeof window.Telegram !== "undefined" &&
      typeof window.Telegram.WebApp !== "undefined";
    setIsTelegramApp(isTelegram);

    if (isTelegram && isMenuOpen) {
      const fetchPartners = async () => {
        try {
          const {
            REACT_APP_SPREADSHEET_ID: SPREADSHEET_ID,
            REACT_APP_API_KEY: API_KEY,
          } = process.env;
          const RANGE = "info-partners";
          const url = `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${RANGE}?key=${API_KEY}`;

          const response = await fetch(url);
          if (!response.ok) throw new Error("Failed to fetch partners");

          const { values } = await response.json();
          if (!values?.length) return;

          const [headers, ...rows] = values;
          const partnersData = rows
            .filter((row) => row[headers.indexOf("Status")] === "on")
            .map((row) =>
              Object.fromEntries(
                headers.map((header, index) => [header, row[index]])
              )
            );

          setPartners(partnersData);
        } catch (error) {
          console.error("Error fetching partners:", error);
        }
      };

      fetchPartners();
    }
  }, [isMenuOpen]);

  // Если приложение не открыто в Telegram, показываем сообщение с ссылкой
  if (!isTelegramApp) {
    return (
      <div className="connect-telegram">
        <p>Подключись на наш телеграм</p>
        <a
          href="https://t.me/CapybaraCambioRobot"
          target="_blank"
          rel="noopener noreferrer"
          className="telegram-link"
        >
          Перейти в Телеграм
        </a>
      </div>
    );
  } else {
    // Отображаем список партнеров, если сайт открыт через Telegram
    return (
      <div className="partners-container">
        <h3>{t("info_partners")}</h3>
        <div className="partners-list">
          {partners.map(({ url, "url-logo": logo, Name }, index) => (
            <a key={index} href={url} target="_blank" rel="noopener noreferrer">
              <img
                src={logo}
                alt={Name}
                className="partner-logo"
                loading="lazy"
              />
            </a>
          ))}
        </div>
      </div>
    );
  }
};

export default PartnersList;
